import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import iceServers from '../config/iceServers';

let localStream;
let localPeerConnection;
const pcs = {};

function Publisher() {
    const { room } = useParams();
    const videoRef = useRef(null);
    const socketRef = useRef(null);

	useEffect(() => {
		const token = localStorage.getItem('access_token');
		const socket = new WebSocket(`wss://test-api.robeff.com/stream/ws/publisher/${room}?token=${token}`);
		socketRef.current = socket;

        socket.onopen = () => {
            console.log('ws opened');

			navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(stream => {
				videoRef.current.srcObject = stream;
				localStream = stream;
			}, error => {
				console.log('Error accessing media devices.', error);
			});
        };

        socket.onclose = () => console.log('ws closed');

        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        socketRef.current.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data);
            switch (parsedMessage.type) {
            case 'answer_sdp_received': {
				console.log('answer_sdp_received invoked');
				const viewer_username = parsedMessage.user;
				console.log('viewer_username', viewer_username);

				console.log('gotRemoteDescription invoked:');
				pcs[viewer_username].setRemoteDescription(parsedMessage.body);
                break;
            }
			case 'join_viewer': {
				console.log('join_viewer invoked');
				const viewer_username = parsedMessage.user;
				console.log('viewer_username', viewer_username);
				pcs[viewer_username] = new RTCPeerConnection(iceServers);

				pcs[viewer_username].onicecandidate = gotLocalIceCandidateOffer.bind(
					this,
					viewer_username
				);
				pcs[viewer_username].addStream(localStream);
				pcs[viewer_username].createOffer().then((offer) => {
					console.log('gotLocalDescription invoked:');
					pcs[viewer_username].setLocalDescription(offer);
				});

				break;
			}
            case 'quit': {
				console.log('quit invoked');
                break;
            }
            default:
                break;
            }
        };
    }, [room]);

    const sendWsMessage = (type, body) => {
        // console.log('sendWsMessage invoked', type, body);
        console.log('sendWsMessage invoked', type);
        socketRef.current.send(JSON.stringify({
            type,
            body,
        }));
    };

	const startStream = () => {

		sendWsMessage('join', {
            room,
            room,
        });
	};
  
	const stopStream = () => {
		for (const [key, value] of Object.entries(pcs)) {
			this.log('closing', value);
			value.close();
			delete this.pcs[key];
		}

		videoRef.current.srcObject.getTracks().forEach(track => track.stop());
		videoRef.current.srcObject = null;

		if (socketRef.current) socketRef.current.close();
	};

    const gotLocalIceCandidateOffer = (viewer_username, event) => {
        // console.log('gotLocalIceCandidateOffer invoked', event.candidate, localPeerConnection.localDescription);
        console.log('gotLocalIceCandidateOffer invoked');

        if (!event.candidate) {
			const offer = pcs[viewer_username].localDescription;
            sendWsMessage('send_offer', {
                room,
                viewer_username,
                sdp: offer,
            });
        }
    };

	return (
		<div>
			<h2>Publisher</h2>
			<button onClick={startStream}>Start Stream</button>
			<button onClick={stopStream}>Stop Stream</button>
			<video ref={videoRef} autoPlay playsInline muted style={{ transform: "scaleX(-1)" }}></video>
		</div>
	);
}

export default Publisher;