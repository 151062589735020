const iceServers = {
	iceServers: [
        {
            urls: [
                'stun:stun.l.google.com:19302',
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
                'stun:stun3.l.google.com:19302',
                'stun:stun4.l.google.com:19302',
            ],
        },
        {
            urls: 'TURN:freestun.net:3478',
            credential: 'free',
            username: 'free',
        },
	],
};

// const iceServers = {
// 	iceServers: [
//         {
//             urls: [
//                 'stun:stun.l.google.com:19302',
//                 'stun:stun1.l.google.com:19302',
//             ],
//         },
//         {
//             urls: 'turn:numb.viagenie.ca',
//             credential: 'muazkh',
//             username: 'webrtc@live.com',
//         }
// 	],
// };
  
export default iceServers;
  