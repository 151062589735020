import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

async function getPublishers() {
	const token = localStorage.getItem('access_token');

	try {
		const response = await axios.get('https://test-api.robeff.com/stream/publishers', {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
		return response.data; // JSON verisini doğrudan döndür
	} catch (error) {
		console.error('Error fetching publishers:', error);
		return []; // Hata durumunda boş dizi döndür
	}
}

function ViewerSelection() {
    const [publishers, setPublishers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchPublishers() {
            const data = await getPublishers();
            setPublishers(data);
        }

        fetchPublishers();
    }, []); // Boş bağımlılık dizisi: sadece bileşen ilk kez render edildiğinde çalışır

    const handleSelect = (publisher) => {
        navigate(`/viewer/${publisher}`);
    };

    return (
        <div>
            <h2>Select a Publisher to Watch</h2>
            <ul>
                {publishers.map(publisher => (
                    <li key={publisher}>
                        <button onClick={() => handleSelect(publisher)}>{publisher}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ViewerSelection;
