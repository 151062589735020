import React, { useEffect, useRef } from 'react';
import SimplePeer from 'simple-peer';
import { useParams } from 'react-router-dom';
import iceServers from '../config/iceServers';

let localPeerConnection;

function Viewer() {
    const { room } = useParams();
    const videoRef = useRef(null);
    const peerRef = useRef(null);
    const socketRef = useRef(null);

	useEffect(() => {
		const token = localStorage.getItem('access_token');
        const socket = new WebSocket(`wss://test-api.robeff.com/stream/ws/viewer/${room}?token=${token}`);
		socketRef.current = socket;

        socket.onopen = () => {
            console.log('ws opened');
			sendWsMessage('join', {
				room,
				room,
			});
        };

        socket.onclose = () => console.log('ws closed');

        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        socketRef.current.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data);
			console.log('parsedMessage type: ', parsedMessage.type);
            switch (parsedMessage.type) {
				case 'joined': {
					console.log('joined invoked');
					const body = parsedMessage.body;
					console.log('users in this channel', body);
					break;
				}
				case 'offer_sdp_received': {
					console.log('offer_sdp_received invoked');
					const offer = parsedMessage.body;
					onAnswer(offer);
					break;
				}
				case 'answer_sdp_received': {
					console.log('answer_sdp_received invoked');
					gotRemoteDescription(parsedMessage.body);
					break;
				}
				case 'quit': {
					console.log('quit invoked');
					break;
				}
				default:
					break;
            }
        };
    }, [room]);

	const sendWsMessage = (type, body) => {
        // console.log('sendWsMessage invoked', type, body);
        console.log('sendWsMessage invoked', type);
        socketRef.current.send(JSON.stringify({
            type,
            body,
        }));
    };

    const gotAnswerDescription = (answer) => {
        // console.log('gotAnswerDescription invoked:', answer);
        console.log('gotAnswerDescription invoked:');
        localPeerConnection.setLocalDescription(answer);
    };

    const gotRemoteDescription = (answer) => {
        // console.log('gotRemoteDescription invoked:', answer);
        console.log('gotRemoteDescription invoked:');
        localPeerConnection.setRemoteDescription(answer);
    };

	const gotRemoteStreamOnTrack = (event) => {
		console.log('gotRemoteStreamOnTrack invoked');
		videoRef.current.srcObject = event.streams[0];
	}

    const gotLocalIceCandidateAnswer = (event) => {
        // console.log('gotLocalIceCandidateAnswer invoked', event.candidate, localPeerConnection.localDescription);
        console.log('gotLocalIceCandidateAnswer invoked');

        if (!event.candidate) {
            const answer = localPeerConnection.localDescription;
            sendWsMessage('send_answer', {
                room,
                room,
                sdp: answer,
            });
        }
    };

	const onAnswer = (offer) => {
        localPeerConnection = new RTCPeerConnection(iceServers);
        console.log('setup gotLocalIceCandidateAnswer');
        localPeerConnection.onicecandidate = gotLocalIceCandidateAnswer;

        console.log('setup gotRemoteStream');
        // localPeerConnection.onaddstream = gotRemoteStream;
		localPeerConnection.ontrack = gotRemoteStreamOnTrack;

        localPeerConnection.setRemoteDescription(offer);
        localPeerConnection.createAnswer().then(gotAnswerDescription);
    };

    return (
        <div>
            <h2>Watching room: {room}</h2>
			<video ref={videoRef} autoPlay playsInline style={{ transform: "scaleX(-1)" }}></video>
        </div>
    );
}

export default Viewer;