// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { apiRequest } from '../utils/api';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

//   const handleLogin = async (role) => {
//     try {
//       const response = await apiRequest('https://test-api.robeff.com/api/signin', 'POST', { username, password });
//       localStorage.setItem('access_token', response["access_token"]);

//       if (role === 'publisher') {
//         navigate('/publish'); // history.push yerine navigate kullanımı
//       } else if (role === 'viewer') {
//         navigate('/viewers');
//       }
//     } catch (error) {
//       console.error('Login failed', error);
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <input
//         type="text"
//         placeholder="Username"
//         value={username}
//         onChange={(e) => setUsername(e.target.value)}
//       />
//       <br />
//       <input
//         type="password"
//         placeholder="Password"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//       />
//       <br />
//       <button onClick={() => handleLogin('publisher')}>Yayıncı Girişi</button>
//       <button onClick={() => handleLogin('viewer')}>İzleyici Girişi</button>
//     </div>
//   );
// };

// export default Login;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

async function getAccessToken (data) {
	return await axios.post('https://test-api.robeff.com/api/signin', data);
}

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('publisher');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
			const response = await getAccessToken({ username, password });
            const token = response.data['access_token'];

            localStorage.setItem('access_token', token);

            if (role === 'publisher') {
                navigate(`/publisher/${username}`);
            } else {
                navigate(`/viewerSelection`);
            }
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    return (
        <div>
            <h2>Login</h2>
            <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="publisher">Publisher</option>
                <option value="viewer">Viewer</option>
            </select>
            <button onClick={handleLogin}>Login</button>
        </div>
    );
}

export default Login;
