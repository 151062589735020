// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Switch yerine Routes kullanımı
// import Login from './components/Login';
// import Publisher from './components/Publisher';
// import Viewers from './components/Viewers';
// import Viewer from './components/Viewer';

// const App = () => {
//   return (
//     <Router>
//       <Routes> {}
//         <Route path="/" element={<Login />} />
//         <Route path="/publish" element={<Publisher />} />
//         <Route path="/viewers" element={<Viewers />} />
//         <Route path="/view/:id" element={<Viewer />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Publisher from './components/Publisher';
import ViewerSelection from './components/ViewerSelection';
import Viewer from './components/Viewer';
import Login from './components/Login';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/publisher/:room" element={<Publisher />} />
                <Route path="/viewer/:room" element={<Viewer />} />
                <Route path="/viewerSelection/" element={<ViewerSelection />} />
				</Routes>
        </Router>
    );
}

export default App;